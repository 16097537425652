<script>
import {GameStatus} from "../gameCommon";

export default {
  name: "RoomTournamentListItem",
  props: {
    roomNumber: {
      type: [Number, String],
      required: true,
    },
    roomUri: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: [Number, String],
      required: true,
    },
    buyin: {
      type: [Number],
      required: true,
    },
    maxBuyin: {
      type: [Number],
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    totalPrize: {
      type: Number,
      required: true,
    },
    entryCnt: {
      type: Number,
      required: true,
    },
    curEntryCnt: {
      type: Number,
      required: true,
    },
    entryBlindLimit: {
      type: Number,
      required: true,
    },
    curBlindSeq: {
      type: Number,
      required: true,
    },
    openTime: {
      type: String,
      required: true,
    },
    tGameStart: {
      type: Number,
      required: true,
    },
    blindTable:{
      type: Array,
      required: true,
    },
    now:{
      type: Number,
      required: true,
    },
    isMain: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  computed: {
    GameStatus() {
      return GameStatus
    },
    getCurrentBlind(){
      return this.blindTable.find( blind => blind.seq === this.curBlindSeq );
    },
    disallowEntryCount(){
      return this.curEntryCnt >= this.entryCnt;
    },
    disallowEntryBlindLv(){
      return this.entryBlindLimit > 0 && this.getCurrentBlind.lv >= this.entryBlindLimit;
    },
    isEntryAble(){
      return !this.disallowEntryCount && !this.disallowEntryBlindLv;
    },
    //남은 블라인드 제한 시간 가져오기
    getRemainBlindLimitTime(){
      const currentBlind = this.getCurrentBlind;
      if( currentBlind.lv >= this.entryBlindLimit ){
        return 0;
      }else{
        const blindSec = currentBlind.sec;
        const blindStartElapsedTime = (this.now - currentBlind.start_at) / 1000;
        const remainBlinds = this.blindTable.filter(blind => blind.seq >= currentBlind.seq && blind.lv < this.entryBlindLimit )
        const remainSec = Math.floor((remainBlinds.length * blindSec) - blindStartElapsedTime);
        return remainSec < 1 ? 0 : remainSec;
      }
    },
    //남은 블라인드 제한 시간 카운트
    remainBlindLimitTimeToCount(){
      return this.formatTime(this.getRemainBlindLimitTime);
    },
    //게임시작 경과 시간 카운트
    elapsedTime(){
      return this.formatTime(Math.floor((this.now - this.tGameStart) / 1000));
    },
    remainOpenTimeToCount(){
      const remain = Math.floor((Number(new Date(this.openTime) - this.now)) / 1000);
      return this.formatTime(remain < 0 ? 0 : remain);
    }
  },
  methods: {
    isStatus( status ){
      return this.status === status
    },
    formatTime(sec) {
      if( !(typeof sec === 'number' && Number.isFinite(sec)) ){
        return '';
      }

      const hours = Math.floor(sec /  (60 * 60)).toString(); // 전체 시간 수
      const minutes = Math.floor(sec % (60 * 60) / 60).toString(); // 전체 분 수
      const seconds = Math.floor(sec % 60).toString(); // 전체 분 수
      return `${hours.padStart(2, '0')} : ${minutes.padStart(2, '0')} : ${seconds.padStart(2, '0')}`;
    },
    showTournamentInfoModal() {
      // if( this.isStatus(GameStatus.End) || this.isStatus(GameStatus.Pause) ){
      //   return;
      // }
      this.$emit('show', {roomCode: this.roomNumber, roomUri: this.roomUri});
    }
  }
}
</script>

<template>
  <div :class="{
    'col-lg-6' : isMain
  }">
    <div class="room_info"
         :class="{
          regist: isStatus(GameStatus.Ready),
          ongoing: isStatus(GameStatus.Playing),
          tourna_bg01: isStatus(GameStatus.Ready) || isStatus(GameStatus.Playing),
          tourna_bg02: isStatus(GameStatus.BeforeStart),
        }"
        @click="showTournamentInfoModal()"
    >
      <div class="area_left">
        <div class="mb-3">
          <div v-if="!isMain" class="user_num gold">
            <span class="material-symbols-rounded">person</span>
            <div class="d-inline-block">
              <span :class="{yellow: count > 0}">{{ count }}</span><span>/{{ limit }}</span>
            </div>
          </div>
          <div class="tit">{{ title }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="room_state">
            <div class="date">

              <div class="txt" v-if="isStatus(GameStatus.BeforeStart)">남은시간</div>
              <div class="txt" v-else-if="isStatus(GameStatus.Ready)">진행대기</div>
              <div class="txt" v-else-if="isStatus(GameStatus.End)">게임종료</div>
              <div class="txt" v-else-if="isStatus(GameStatus.Pause)">게임중지</div>
              <div class="txt" v-else-if="isEntryAble">참가가능</div>
              <div class="txt" v-else>참가마감</div>

              <div v-if="isStatus(GameStatus.BeforeStart)">{{ remainOpenTimeToCount }}</div>
              <div v-else-if="isStatus(GameStatus.Ready)">참여 인원 모집중</div>
              <div v-else-if="isStatus(GameStatus.End)">게임종료</div>
              <div v-else-if="isStatus(GameStatus.Pause)">00 : 00 : 00</div>
              <div v-else-if="isEntryAble"> {{ this.getRemainBlindLimitTime > 0 ? remainBlindLimitTimeToCount : elapsedTime}} </div>
              <div v-else> {{ elapsedTime }} </div>

            </div>
          </div>
          <div class="prize_money">
            <div class="type">현재 블라인드 레벨 <span class="point_color fw-bold">{{ this.getCurrentBlind.lv }}</span></div>
            <div class="money">상금 {{ totalPrize | formatCash }}</div>
          </div>
        </div>
      </div>

      <div class="state" v-if="isStatus(GameStatus.BeforeStart)">예정</div>
      <div class="state" v-else-if="isStatus(GameStatus.Ready)">대기</div>
      <div class="state" v-else-if="isStatus(GameStatus.End)">종료</div>
      <div class="state" v-else-if="isStatus(GameStatus.Pause)">중지</div>
      <div class="state" v-else-if="isEntryAble">진행중</div>
      <div class="state" v-else>진행중</div>
    </div>
  </div>
</template>